import "../styles/main.scss";
import { multipleSplats, updateFluid } from "./fluid";
import ueLogo from "../assets/ue_logo_only.png";
import arkitLogo from "../assets/apple-arkit.png";
import * as THREE from "three";
import Swal from "sweetalert2/dist/sweetalert2.js";

import pixiLogo from "../assets/pixi_logo.png";
// Initialize WebGL fluid animation
const canvas = document.getElementById("fluidCanvas");
const gl = canvas.getContext("webgl");

// Set up images
// const ueLogoImg = document.querySelector('img[alt="Unreal Engine 5"]');
// ueLogoImg.src = ueLogo;

// const arkitLogoImg = document.querySelector('img[alt="Apple ARKit"]');
// arkitLogoImg.src = arkitLogo;

const pixiLogoImg = document.querySelector("#pixi_logo");
if (pixiLogoImg) {
  pixiLogoImg.src = pixiLogo;
}

// Set canvas size
function resizeCanvas() {
  canvas.width = window.innerWidth;
  canvas.height = window.innerHeight;
}

// Initial resize
resizeCanvas();

// Handle window resize
window.addEventListener("resize", resizeCanvas);

// Start fluid simulation
multipleSplats(parseInt(Math.random() * 2) + 2);
updateFluid();

// Preload textures one by one with delay
const textureLoader = new THREE.TextureLoader();
const textures = {};

const loadTextureWithDelay = async (url, key, delay = 100) => {
  await new Promise((resolve) => setTimeout(resolve, delay));
  return new Promise((resolve, reject) => {
    textureLoader.load(
      url,
      (texture) => {
        textures[key] = texture;
        console.log(`Loaded texture: ${key}`);
        resolve(texture);
      },
      undefined,
      reject
    );
  });
};

const preloadTextures = async () => {
  try {
    console.log("Starting to preload textures...");

    await loadTextureWithDelay(
      "/textures/processed/earth-lights.jpg",
      "lightsMap",
      0
    );
    await loadTextureWithDelay(
      "/textures/processed/earth-normal.jpg",
      "normalMap",
      0
    );
    await loadTextureWithDelay(
      "/textures/processed/earth-day.jpg",
      "dayMap",
      0
    );
    await loadTextureWithDelay(
      "/textures/processed/earth-water.png",
      "waterMap",
      0
    );

    console.log("All textures loaded successfully:", textures);
    return textures;
  } catch (error) {
    console.error("Error loading textures:", error);
    console.log(
      "Attempted to load from:",
      window.location.origin + "/textures/processed/earth-lights.jpg"
    );
    return null;
  }
};

let texturesPromise = preloadTextures();

// Lazy load the globe
let globeInstance = null;
const globeSection = document.getElementById("body");

const loadGlobe = async () => {
  if (globeInstance) return;

  try {
    const loadedTextures = await texturesPromise;
    const { Globe } = await import("./globe.js");
    globeInstance = new Globe(loadedTextures);
  } catch (error) {
    console.error("Error initializing globe:", error);
  }
};

// Modify the observer
const observer = new IntersectionObserver(
  (entries) => {
    entries.forEach((entry) => {
      console.log(entry);
      if (
        entry.isIntersecting ||
        entry.boundingClientRect.top < window.innerHeight * 2
      ) {
        loadGlobe();
        observer.disconnect();
      }
    });
  },
  {
    rootMargin: "100% 0px",
    threshold: 0,
  }
);

// Start observing immediately
observer.observe(globeSection);

// Export fluid simulation functions
export function initFluid() {
  console.log("Fluid simulation initialized");
}

// Move openModal function to window object to make it globally accessible
window.openModal = async function () {
  Swal.fire({
    title: "Be the first to know!",
    input: "text",
    inputPlaceholder: "Enter your email address",
    inputAttributes: {
      autocapitalize: "off",
    },
    showCancelButton: false,
    confirmButtonText: "Submit",
    showLoaderOnConfirm: true,
    customClass: "modalSwal",
    preConfirm: async (email) => {
      // Email validation regex
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      // Check if email is valid
      if (!emailRegex.test(email.trim())) {
        Swal.showValidationMessage("Please enter a valid email address");
        return false;
      }

      try {
        const response = await fetch(import.meta.env.VITE_API_ENDPOINT, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email: email.trim() }),
        });
        return response.json();
      } catch (error) {
        Swal.showValidationMessage("Something went wrong. Please try again.");
        return false;
      }
    },
    allowOutsideClick: () => !Swal.isLoading(),
  }).then((result) => {
    if (!result.isConfirmed || !result.value) return;

    switch (result.value.code) {
      case "email_registered":
        Swal.fire({
          icon: "success",
          title: "Confirmed!",
          text: "We’ll let you know when Pixi is ready for you.",
          customClass: "modalSwal",
        });
        break;
      case "email_already_registered":
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "You are already on the update list.",
          customClass: "modalSwal",
        });
        break;
    }
  });
};

// Add this function to handle smooth scrolling with offset
function initSmoothScroll() {
  document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
    anchor.addEventListener("click", function (e) {
      e.preventDefault();
      const targetId = this.getAttribute("href");
      if (targetId === "#") return;

      const targetElement = document.querySelector(targetId);
      if (!targetElement) {
        console.log("Target element not found:", targetId);
        return;
      }

      const scrollContainer = document.querySelector(".main-scroll-container");
      const navHeight = document.querySelector("[data-nav]").offsetHeight;

      // Get the target element's offset from the top of its offset parent
      const targetOffset = targetElement.offsetTop;

      // Calculate the desired scroll position
      const offsetPosition = targetOffset - navHeight - 32;

      console.log({
        targetId,
        targetOffset,
        navHeight,
        offsetPosition,
        scrollTop: scrollContainer.scrollTop,
        elementOffsetTop: targetElement.offsetTop,
      });

      scrollContainer.scrollTo({
        top: Math.max(0, offsetPosition),
        behavior: "smooth",
      });
    });
  });
}

// Initialize immediately if DOM is loaded, otherwise wait for DOMContentLoaded
if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", initSmoothScroll);
  document.addEventListener("DOMContentLoaded", () => {
    document.querySelectorAll(".launch-button").forEach((button) => {
      button.addEventListener("click", openModal);
    });
  });
} else {
  initSmoothScroll();
  document.addEventListener("DOMContentLoaded", () => {
    document.querySelectorAll(".launch-button").forEach((button) => {
      button.addEventListener("click", openModal);
    });
  });
}
